<template>
  <div>
    <b-container>
      <b-card class="my-4 pb-0">
        <div>
          <h2 class="text-center text-primary font-size-20 font-weight-bold position-relative main-header mb-5">
            {{$t('endUser.mediaCenter') }}</h2>
          <div class="pt-5 pb-0 mb-500">
            <b-container>
              <div class="d-flex justify-content-between align-items-center mb-5">
                <h2 class="font-size-21 font-weight-bold ibbil-vertical-dark-line position-relative"> {{$t('endUser.news') }}</h2>
                <div class="justify-left">
                  <button class="bg-transparent border border-warning font-bold py-2 px-4 iq-border-radius-5" @click="$router.push({name: 'news'})">
                    <span> {{$t('endUser.seeAll') }}</span>
                  </button>
                </div>
              </div>
              <b-row>
                <b-col md="6" lg="3" class="mt-30" v-for="(i, key) in allNews.slice(-4)" :key="key">
                  <div class="transition-top-card position-relative cursor-pointer" @click="$router.push({name: 'newDetails', params: {newsID: i.id}})">
                    <div class="top-card rounded-top overflow-hidden">
                      <section :style="{
              'background-size': 'cover',
              'background-position': 'center',
              'background-image':
           'url(' + i.featured_image + ')' }"
                               class="w-100 h-250px position-relative">
                        <div class="overlay-card"></div>
                      </section>
                      <!--              <img class="w-100" :src='i.image' :alt="i.name"/>-->
                      <div class="bg-white position-absolute py-2 bottom-transition-card px-3  w-100">
                        <span class="position-absolute bg-warning text-white p-1  position-absolute tag-card" v-if="i.category">{{i.category.title}} </span>
                        <span class=" d-block text-gray text-center m-0 font-weight-bold my-2">{{i.created_at}}</span>
                        <h4 class="text-primary font-weight-bold font-size-16 text-center">{{i.title.length > 20 ? i.title.slice(0, 20) + '...' : i.title}}</h4>
                        <div  class="bottom-card bg-white">
                          <p style="" class="text-black mt-2 m-0 font-size-14 text-center">{{i.content.length >= 200 ? i.content.slice(0,200) + '...' : i.content}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <div>
          <div class="pt-5 pb-0">
            <b-container>
              <div class="d-flex justify-content-between align-items-center mb-5">
                <h2 class="font-size-21 font-weight-bold ibbil-vertical-dark-line position-relative"> {{$t('endUser.events') }}</h2>
                <div class="justify-left">
                  <button class="bg-transparent border border-warning font-bold py-2 px-4 iq-border-radius-5" @click="$router.push({name: 'events'})">
                    <span> {{$t('endUser.seeAll') }}</span>
                  </button>
                </div>
              </div>
              <b-row>
                <b-col md="6" lg="3" v-for="(i, key) in allEvents.slice(-4)" :key="key">
                  <event-card :item="i" @click.native="$router.push({name: 'eventsDetails', params: {eventsId: i.id}})" />
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <div>
          <div class="pt-5 pb-0">
            <b-container>
              <div class="d-flex justify-content-between align-items-center mb-5">
                <h2 class="font-size-21 font-weight-bold ibbil-vertical-dark-line position-relative"> {{$t('endUser.photoGallery') }}</h2>
                <div class="justify-left">
                  <button class="bg-transparent border border-warning font-bold py-2 px-4 iq-border-radius-5" @click="$router.push({name: 'images'})">
                    <span> {{$t('endUser.seeAll') }}</span>
                  </button>
                </div>
              </div>
              <b-row>
                <b-col md="6" lg="3" v-for="(i, key) in allGallery.slice(-4)" :key="key">
                  <event-card :item="i" type="image" @click.native="$router.push({name: 'imageDetails', params: {imageId: i.id}})"/>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
        <div>
          <div class="pt-5 pb-0">
            <b-container>
              <div class="d-flex justify-content-between align-items-center mb-5">
                <h2 class="font-size-21 font-weight-bold ibbil-vertical-dark-line position-relative"> {{$t('endUser.videoGallery') }}</h2>
                <div class="justify-left">
                  <button class="bg-transparent border border-warning font-bold py-2 px-4 iq-border-radius-5"  @click="$router.push({name: 'videos'})">
                    <span> {{$t('endUser.seeAll') }}</span>
                  </button>
                </div>
              </div>
              <b-row>
                <b-col md="6" lg="3" v-for="(i, key) in allVideos" :key="key">
                  <event-card :item="i" type="video" @click.native="$router.push({name: 'videoDetails', params: {videoId : i.id }})"/>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </div>
      </b-card>
    </b-container>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
import eventCard from '@/components/eventCard'
import allImages from '@/FackApi/json/images.json'
import homeServices from '@/modules/primaryPages/home/services/home'
export default {
  name: 'mediaCenter',
  mounted () {
    core.index()
  },
  components: {
    eventCard
  },
  data () {
    return {
      allNews: [],
      allEvents: [],
      allGallery: [],
      allVideos: [],
      images: allImages,
      vedios: [
        {
          title: 'الحفل الختامي لمهرجان الملك عبدالعزيز',
          content: 'رعى خادم الحرمين الشريفين، الملك سلمان بن عبدالعزيز آل سعود.',
          video: 'https://ibbil.dafa.dev/videos/1.mp4'
        },
        {
          title: 'الحفل الختامي لمهرجان الملك عبدالعزيز',
          content: 'رعى خادم الحرمين الشريفين، الملك سلمان بن عبدالعزيز آل سعود.',
          video: 'https://ibbil.dafa.dev/videos/2.mp4'
        },
        {
          title: 'الحفل الختامي لمهرجان الملك عبدالعزيز',
          content: 'رعى خادم الحرمين الشريفين، الملك سلمان بن عبدالعزيز آل سعود.',
          video: 'https://ibbil.dafa.dev/videos/3.mp4'
        },
        {
          title: 'الحفل الختامي لمهرجان الملك عبدالعزيز',
          content: 'رعى خادم الحرمين الشريفين، الملك سلمان بن عبدالعزيز آل سعود.',
          video: 'https://ibbil.dafa.dev/videos/4.mp4'
        }
      ]
    }
  },
  methods: {
    getNews () {
      homeServices.getAllNews().then(res => {
        this.allNews = res.data.data
      })
    },
    getEvents () {
      homeServices.getAllEvents().then(res => {
        this.allEvents = res.data.data
      })
    },
    getGallery () {
      homeServices.getAllGallery().then(res => {
        this.allGallery = res.data.data
      })
    },
    getVideos () {
      homeServices.getAllVideos().then(res => {
        this.allVideos = res.data.data
      })
    }
  },
  created () {
    this.getNews()
    this.getEvents()
    this.getGallery()
    this.getVideos()
  }
}
</script>
<style lang="scss">
.transition-top-card {
  cursor: pointer;
}
.transition-top-card:hover .bottom-transition-card {
  transform: translateY(50%);
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.transition-top-card:hover .overlay-card {
  position: absolute;
  background: rgb(43 88 66 / 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.transition-top-card:hover .bottom-transition-card p {
  font-size: initial;
  height: initial;
  visibility: visible;
}
.bottom-transition-card {
  box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 /
    0.1);
  bottom: 0;
  transition: 0.4s linear;
  transform: translateY(100%);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}
.bottom-transition-card p {
  height: 0;
  visibility: hidden;
}
.mb-500 {
  margin-bottom: 150px !important;
}
.tag-card {
  border-radius: 5px;
  top: -50px;
  right: 10px;
}
.mb-30 {
  margin-bottom: 140px;
}
@media (max-width: 768px) {
  .mt-30 {
    margin-top: 120px;
  }
  .mt-30:first-of-type {
    margin-top: 0px;
  }
  .mb-30 {
    margin-bottom: 140px;
  }
  .mb-30:first-of-type {
    margin-bottom: 0px;
  }
}
@media(min-width:768px) and (max-width: 992px) {
  .mt-30 {
    margin-top: 120px;
  }
  .mt-30:first-of-type, .mt-30:nth-of-type(2)  {
    margin-top: 0px;
  }
  .mb-30 {
    margin-bottom: 140px;
  }
  .mb-30:first-of-type, .mt-30:nth-of-type(2) {
    margin-bottom: 0px;
  }
}
</style>
