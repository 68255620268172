import { marketPlace, website } from '@/axios'

export default {
  getNewStores () {
    return marketPlace().get('recently-added-stores')
  },
  getAllEvents () {
    return website().get('user/events')
  },
  getMostEvents () {
    return website().get('user/events/most-watched')
  },
  eventDetails (id) {
    return website().get(`user/events/${id}`)
  },
  getAllNews () {
    return website().get('user/news')
  },
  getMostNews () {
    return website().get('user/news/most-watched')
  },
  newsDetails (id) {
    return website().get(`user/news/${id}`)
  },
  getAllGallery () {
    return website().get('user/galleries/image')
  },
  getMostImage () {
    return website().get('user/galleries/image/most-watched')
  },
  galleryDetails (id) {
    return website().get(`user/galleries/image/${id}`)
  },
  getAllVideos () {
    return website().get('user/galleries/video')
  },
  getVideoDetails (id) {
    return website().get(`user/galleries/video/${id}`)
  },
  getMostVideos () {
    return website().get('user/galleries/video/most-watched')
  }
}
