<template>
  <div>
    <section v-if="type !== 'video'" :style="{
              'background-size': 'cover',
              'background-position': 'center',
              'background-image':
           'url(' + item.featured_image + ')' }"
             class="w-100 h-250px position-relative cursor-pointer">
      <div class="overlay-image" v-if="type ==='image'">
        +{{ item.media_count }} صوره
      </div>
    </section>
    <section v-else class="w-100 h-250px position-relative cursor-pointer">
      <video autoplay muted class="w-100 h-100" id="myVideo">
        <source :src="item.featured_image" type="video/mp4">
      </video>
      <div class="overlay-image" v-if="type ==='video'">
        <i class="las la-video font-size-28"></i>
      </div>
    </section>
    <h5 class="text-primary mt-3 mb-1">{{item.title.length > 37 ? item.title.substring(0, 37) + '...' : item.title}}</h5>
    <p class="text-justify">{{item.content.length > 157 ? item.content.substring(0, 157) + '...' : item.content}}</p>
  </div>
</template>

<script>
export default {
  name: 'eventCard',
  props: ['item', 'type']
}
</script>

<style scoped>
.overlay-image {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 5px;
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0, 0, 0, 0.78) 100%);
  color: white;
  text-align: center;
  height: 100%;
  display: flex;
  align-items: end;
  justify-content: center;
}
</style>
